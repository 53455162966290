<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：企业管理 > <b>合作中心</b></span>
        </div>
        <div class="project-info">
            <div class="project-condition">
                <div class="flex-center">
                    <el-input v-model="search_company_name" placeholder="请输入合作中心名称"></el-input>
                    <el-select v-model="search_is_blacklist" placeholder="合作中心状态">
                        <el-option v-for="item in companyStates" :key="item.key"
                            :label="item.value"
                            :value="item.key">
                        </el-option>
                    </el-select>
                    <el-button type="primary" @click="search">搜索</el-button>
                </div>
                <div class="flex-center">
                    <el-button type="primary" @click="addCompany(false)">添加</el-button>
                    <el-button type="primary" @click="exprotCompany()">导出</el-button>
                </div>
            </div>
            <div class="project-list">
                <el-table :data="tableData"
                          header-row-class-name="table-header"
                          style="width: 100%">
                    <el-table-column
                        align="center"
                        label="序号"
                        width="80">
                        <template slot-scope="scope">
                            <div class="table-id">
                                {{ scope.$index + 1 }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="合作中心名称" min-width="200px">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.company_name }}<i class="text-red" v-if="scope.row.is_overdue">(营业执照已过期)</i>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="联系人">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.company_tel_name || '--' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="联系电话">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.company_tel }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" prop="operate" width="200">
                        <template slot-scope="scope">
                            <div class="table-operate">
                                <el-button size="small" type="text" @click="addCompany(scope.row)">修改</el-button>
                                <el-button v-if="isDelete" size="small" style="color: #e4393c" type="text"
                                           @click="delItem(scope.row.id)">删除
                                </el-button>
                                <el-button v-if="isOutBlack && scope.row.is_blacklist === 1" size="small"
                                           style="color: #e4393c" type="text"
                                           @click="setBlackList(scope.row.id,scope.row.is_blacklist)">解除黑名单
                                </el-button>
                                <el-button v-if="!isOutBlack && scope.row.is_blacklist === 1" size="small"
                                           style="color: #e4393c" type="text">已拉入黑名单
                                </el-button>
                                <el-button v-if="isInBlack && scope.row.is_blacklist === 0" size="small"
                                           style="color: #3b77e7" type="text"
                                           @click="setBlackList(scope.row.id,scope.row.is_blacklist)">拉入黑名单
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    :current-page.sync="page"
                    :page-size="limit"
                    :total="pageTotal"
                    background
                    class="page-all"
                    layout="prev, pager, next"
                    @current-change="setPage">
                </el-pagination>
            </div>
        </div>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="editVisible"
            center
            width="40%">
            <span slot="title" class="dialog-title">添加企业</span>
            <el-form
                ref="employeeForm"
                :model="company"
                :rules="rules"
                class="employee-form"
                labelWidth="120px">
                <el-form-item label="合作中心名称" prop="company_name">
                    <el-input v-model="company.company_name" placeholder="请输入合作中心名称"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="company_tel_name">
                    <el-input
                        v-model="company.company_tel_name"
                        placeholder="请输入联系人"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="company_tel">
                    <el-input
                        v-model="company.company_tel"
                        placeholder="请输入联系电话"
                    ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                 <el-button type="primary" @click="submitDepartment('employeeForm')">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {genUpToken, qiniuaddr, uploadPath} from "../../tools/CryptoJS";
import config from "../../config";
import {companyStates} from "../../tools";

export default {
    name: "list",
    data() {
        return {
            companyStates:companyStates,
            isDelete: this.$store.state.user.mid === config.chenjing,
            isInBlack: this.$store.state.user.mid === config.chenjing ,
            isOutBlack: this.$store.state.user.mid === config.zhangque,
            search_is_blacklist: '',
            search_enterprise_type: 3,
            search_company_name: '',
            search_company_tel: '',
            tableData: [],
            page: 1,
            pageTotal: 1,
            limit: 10,
            editVisible: false,

            QiniuData: {
                fname: "", //  文件名
                key: "", //文件名字处理
                token: "", //七牛云token
            },
            qiniuaddr: qiniuaddr, // 七牛云的图片外链地址 你的七牛云里配置有
            uploadPath: uploadPath, // 七牛云的图片外链地址 你的七牛云里配置有

            cur_id: '',
            company: {
                company_name: '',
                company_tel: '',
                //下游
                company_tel_name: '',
                businessLicense: '',
                businessLicense_time: [],
                //上游
                invoice_title: '',
                duty_paragraph: '',
                bank_name: '',
                bank_account: '',
                address: '',
            },
            rules: {
                company_name: [
                    {required: true, message: '请输入合作中心名称', trigger: 'blur'}
                ],
                company_tel_name: [
                    {required: true, message: '请输入联系人', trigger: 'blur'}
                ],
                company_tel: [
                    {required: true, message: '请输入联系电话', trigger: 'blur'}
                ],
            },
        }
    },
    created() {
        let token = genUpToken();
        this.QiniuData.token = token;
        this.$emit('setIndex', [6,2])
        this.init()
    },
    methods: {
        //  搜素
        search() {
            this.page = 1
            this.init()
        },
        // 分页变化
        setPage(val) {
            this.page = val
            this.init()
        },
        //  页面加载时请求
        init() {
            this.$api.apiContent
                .getEnterpriseList({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    search_is_blacklist: this.search_is_blacklist,
                    search_enterprise_type: this.search_enterprise_type,
                    search_company_name: this.search_company_name,
                    search_company_tel: this.search_company_tel,
                    page: this.page,
                    limit: this.limit,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            search_is_blacklist: this.search_is_blacklist,
                            search_enterprise_type: this.search_enterprise_type,
                            search_company_name: this.search_company_name,
                            search_company_tel: this.search_company_tel,
                            page: this.page,
                            limit: this.limit,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.list.map(() => {
                    })
                    this.tableData = res.data.list;
                    this.pageTotal = res.data.count;
                })
                .catch(() => {
                    this.tableData = [];
                });
        },
        addCompany(item) {
            if (item) {
                this.cur_id = item.id
                this.company = {
                    company_name:item.company_name,
                    company_tel_name:item.company_tel_name,
                    company_tel:item.company_tel,
                    businessLicense:'',
                    businessLicense_time:'',
                }
            } else {
                this.cur_id = ''
                this.company = {
                    company_name: '',
                    company_tel_name: '',
                    company_tel: '',
                    businessLicense: '',
                    businessLicense_time: '',
                }
            }
            this.editVisible = true
            this.$refs.employeeForm.clearValidate()
        },
        submitDepartment(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.cur_id) {
                        this.updateEnterprise();
                    } else {
                        this.addEnterprise();
                    }
                }
            });
        },
        addEnterprise() {
            this.$api.apiContent
                .addEnterprise({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    enterprise_type: this.search_enterprise_type,
                    company_name: this.company.company_name,
                    company_tel_name: this.company.company_tel_name,
                    company_tel: this.company.company_tel,
                    businessLicense: this.company.businessLicense,
                    businessLicense_start_time: this.company.businessLicense_time[0],
                    businessLicense_end_time: this.company.businessLicense_time[1],
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        enterprise_type: this.search_enterprise_type,
                        company_tel_name: this.company.company_tel_name,
                        company_name: this.company.company_name,
                        company_tel: this.company.company_tel,
                        businessLicense: this.company.businessLicense,
                        businessLicense_start_time: this.company.businessLicense_time[0],
                        businessLicense_end_time: this.company.businessLicense_time[1],
                    }, this.$store.state.user.key),
                })
                .then((res) => {
                    this.closeDialog()
                    this.$message.success(res.msg);
                    this.init()
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        updateEnterprise() {
            this.$api.apiContent
                .updateEnterprise({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.cur_id,
                    enterprise_type: this.search_enterprise_type,
                    company_name: this.company.company_name,
                    company_tel_name: this.company.company_tel_name,
                    company_tel: this.company.company_tel,
                    businessLicense: '',
                    businessLicense_start_time: '',
                    businessLicense_end_time: '',
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.cur_id,
                            enterprise_type: this.search_enterprise_type,
                            company_name: this.company.company_name,
                            company_tel_name: this.company.company_tel_name,
                            company_tel: this.company.company_tel,
                            businessLicense: '',
                            businessLicense_start_time: '',
                            businessLicense_end_time: '',
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog()
                    this.$message.success(res.msg);
                    this.init()
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        exprotCompany() {
            this.$api.apiContent
                .exportEnterpriseList({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: this.search_enterprise_type,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: this.search_enterprise_type,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    let blob = res.data;
                    var downloadElement = document.createElement('a');
                    var href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.style.display = 'none';
                    downloadElement.href = href;
                    downloadElement.setAttribute('download', '合作中心' + new Date().getTime() + '.xls');
                    document.body.appendChild(downloadElement);
//                        downloadElement.execCommand("saveAs",true,"name.xls"); //点击下载
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        delItem(id) {
            let that = this;
            that.$confirm("你确定删除该合作中心吗", "温馨提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                that.$api.apiContent.delEnterprise({
                    token: that.$store.state.user.token,
                    timestamp: that.$tools.requestTime(),
                    id: id,
                    user_id: that.$store.state.user.mid,
                    sign: that.$tools.SHA256(
                        {
                            token: that.$store.state.user.token,
                            timestamp: that.$tools.requestTime(),
                            id: id,
                            user_id: that.$store.state.user.mid
                        },
                        that.$store.state.user.key
                    ),
                })
                    .then(() => {
                        that.$message.success("删除成功");
                        that.init();
                    })
                    .catch((e) => {
                        that.$message.error(e.msg);
                    });
            })
        },
        setBlackList(id, type) {
            let that = this;
            that.$confirm(`你确定将该合作中心${type === 0 ? '拉入' : '移除'}黑名单吗`, "温馨提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                that.$api.apiContent.setBlackList({
                    token: that.$store.state.user.token,
                    timestamp: that.$tools.requestTime(),
                    id: id,
                    user_id: that.$store.state.user.mid,
                    sign: that.$tools.SHA256(
                        {
                            token: that.$store.state.user.token,
                            timestamp: that.$tools.requestTime(),
                            id: id,
                            user_id: that.$store.state.user.mid
                        },
                        that.$store.state.user.key
                    ),
                })
                    .then(() => {
                        that.$message.success("设置成功");
                        that.init();
                    })
                    .catch((e) => {
                        that.$message.error(e.msg);
                    });
            })
        },
        //  上传成功
        uploadSuccess(response, file, fileList) {
            console.log(file);
            console.log(fileList);
            this.company.businessLicense = `${this.qiniuaddr}/${response.key}`

            this.loading.close();
        },
        //  上传失败
        uploadError() {
            this.loading.close();
            this.$message({
                message: "上传出错，请重试！",
                type: "error",
                center: true,
            });
        },
        beforeAvatarUpload(file) {
            //这里的key给加上了时间戳，目的是为了防止上传冲突
            this.QiniuData.fname = file.name;
            this.QiniuData.key = `${new Date().getTime()}${file.name}`;
            this.loading = this.$loading({
                lock: true,
                text: "上传中，请稍后",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
        },
        closeDialog() {
            this.editVisible = false
        }
    },
    watch: {},
    filters: {}

}
</script>

<style lang="scss" scoped>
.page-box {
    .avatar {
        width: 155px;
        height: 100px;
        line-height: 100px;
        border: 1px dotted #3b77e7;
    }
    .el-date-editor{
        width: 100%;
    }

    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }

        }
    }

    .project-info {
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        overflow: hidden;

        .project-condition {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 24px 28px 16px;
            border-bottom: 2px solid #f5f5f5;

            .title-tab {
                .active {
                    background: #3b77e7;
                    color: #fff;
                }
            }

            .el-select {
                margin-right: 20px;
            }

            .el-input {
                margin-right: 20px;
                width: 268px;
            }
        }

        .project-list {
            padding: 34px 28px;
            min-height: 700px;

            .table-id {
                text-align: center;
                font-size: 16px;
                color: #666666;
            }

            .table-item {
                text-align: center;
                font-size: 16px;
                color: #666666;
            }

            .table-operate {
                display: flex;

                .el-button {
                    font-size: 16px;
                }
            }

            .page-all {
                text-align: center;
                padding: 30px 0 0;
            }
        }
    }
}
</style>